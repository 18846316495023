.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
    height: 190px;
}

.portfolio__item h3{
    margin: 1.2rem 0 0rem;
}

.portfolio__item h4{
    margin: 0.1rem 0 1rem;
    color: var(--color-light);
}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* Modal Styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000; /* Update the background color and opacity */
  z-index: 9999;
  color: white; /* Update the text color */
  
}

.modal-content {
  background-color: var(--color-bg-variant); /* Use the same background color as other elements */
  max-width: 600px;
  padding: 20px;
  border-radius: 2rem; /* Increase the border radius to match other elements */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 5px; */
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-primary); /* Update the title color */
}

.modal-close {
  cursor: pointer;
  font-size: 20px;
  color: #999999;
  transition: color 0.3s;
}

.modal-lang { 
  color: var(--color-light);
  margin-bottom: 15px;
}

.modal-close:hover {
  color: #333;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-body p {
  margin-bottom: 10px;
}

.modal-footer {
  text-align: right;
}

.modal-footer button {
  padding: 8px 16px;
  border: none;
  border-radius: 2rem; /* Increase the border radius to match other elements */
  background-color: var(--color-primary-variant); /* Use the same background color as other elements */
  color: var(--color-white); /* Use the same text color as other elements */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-footer button:hover {
  background-color: #0056b3;
}


.misc__container{
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

/* FE & BE divs */
.misc__container > div { 
  background: var(--color-bg-variant);
  padding: 1.5rem 1.5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.misc__container > div:hover{
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.misc__container > div h3{
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.misc__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1rem;
}

.misc__details{
  display: flex;
  gap: 1rem;   
}

.misc__details-icon{
  margin-top: 6px;
  color: var(--color-primary);
}


/* -----------------------MEDIA QUERIES (MEDIUM DEVICES) ---------------- */
@media screen and (max-width: 1575px) {
  .portfolio__container{
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;

  }
  .portfolio__item-image{
      height: 185px;
  }
}

/* -----------------------MEDIA QUERIES (SMALL DEVICES) ---------------- */
@media screen and (max-width: 945px) {
  .portfolio__container{
      grid-template-columns: 1fr;
      gap: 1rem;

  }

}

/* -----------------------MEDIA QUERIES (MEDIUM DEVICES) ---------------- */
@media screen and (max-width: 1024px) {
  .misc__container {
      grid-template-columns: 1fr;

  }

  .misc__container > div {
      width: 80%;
      padding: 2rem;
      margin: 0 auto;
  }

  .misc__content{
      padding: 1rem;
      grid-template-columns: 1fr 1fr;

  }
}

/* -----------------------MEDIA QUERIES (SMALL DEVICES) ---------------- */
@media screen and (max-width: 600px) {
  .misc__container {
      gap: 1rem;
  }

  .misc__container > div {
      width: 100%;
      padding: 2rem 1rem;
      margin: 0 auto;
  }

  .misc__content{
      padding: 1rem;
      grid-template-columns: 1fr;

  }
}
